<template>
  <div class="tz_box">
    <Tabs value="name1">
      <Tab-pane label="优秀从业人员" name="name1">
        <div class="cell_box">
          <!--          <el-table-->
          <!--            v-loading="loading"-->
          <!--            :cell-style="{ padding: 0 }"-->
          <!--            :data="ryList"-->
          <!--            :header-cell-style="{-->
          <!--              backgroundColor: '#49b3ff',-->
          <!--              color: '#515a6e',-->
          <!--            }"-->
          <!--            style="width: 100%"-->
          <!--          >-->
          <!--            <el-table-column-->
          <!--              align="center"-->
          <!--              label="序号"-->
          <!--              type="index"-->
          <!--              width="80"-->
          <!--            ></el-table-column>-->

          <!--            <el-table-column-->
          <!--              align="center"-->
          <!--              label="培训时间"-->
          <!--              prop="enrollTime"-->
          <!--              show-overflow-tooltip-->
          <!--              width="200px"-->
          <!--            >-->
          <!--            </el-table-column>-->
          <!--            <el-table-column-->
          <!--              align="center"-->
          <!--              label="培训名称"-->
          <!--              prop="courseName"-->
          <!--              show-overflow-tooltip-->
          <!--            ></el-table-column>-->
          <!--            <el-table-column-->
          <!--              align="center"-->
          <!--              label="报名状态"-->
          <!--              prop="confirm"-->
          <!--              show-overflow-tooltip-->
          <!--              width="150px"-->
          <!--            >-->
          <!--              <template #default="scope">-->
          <!--                {{ scope.row.confirm == 1 ? "报名成功" : "待缴费" }}-->
          <!--              </template>-->
          <!--            </el-table-column>-->
          <!--            <el-table-column-->
          <!--              align="center"-->
          <!--              label="学习进度"-->
          <!--              prop="studyFinish"-->
          <!--              show-overflow-tooltip-->
          <!--              width="150px"-->
          <!--            >-->
          <!--              <template #default="scope">-->
          <!--                {{ scope.row.studyFinish == 1 ? "已完成" : "未完成" }}-->
          <!--              </template>-->
          <!--            </el-table-column>-->
          <!--            <el-table-column-->
          <!--              align="center"-->
          <!--              label="总学时"-->
          <!--              prop="classHourTotal"-->
          <!--              show-overflow-tooltip-->
          <!--              width="150px"-->
          <!--            >-->
          <!--              <template #default="scope">-->
          <!--                {{ scope.row.classHourTotal }}学时-->
          <!--              </template>-->
          <!--            </el-table-column>-->
          <!--            <el-table-column-->
          <!--              align="center"-->
          <!--              fixed="right"-->
          <!--              label="操作"-->
          <!--              width="80px"-->
          <!--            >-->
          <!--              <template #default="scope">-->
          <!--                <el-button-->
          <!--                  :disabled="scope.row.confirm !== '1'"-->
          <!--                  type="text"-->
          <!--                  @click="goDetail(scope.row)"-->
          <!--                >-->
          <!--                  立即学习-->
          <!--                </el-button>-->
          <!--              </template>-->
          <!--            </el-table-column>-->
          <!--          </el-table>-->
        </div>
        <!--        <Page-->
        <!--          v-if="ryList.length != 0"-->
        <!--          :current="params.pageNo"-->
        <!--          :page-size="params.pageSize"-->
        <!--          :total="total"-->
        <!--          class="common_page_box"-->
        <!--          show-total-->
        <!--          @on-change="changePage"-->
        <!--        ></Page>-->
      </Tab-pane>
    </Tabs>
    <ksLogin ref="ksbox"></ksLogin>
    <detailModal ref="detailModal"></detailModal>
  </div>
</template>

<script>
import ksLogin from "@/components/ks_login.vue";
import { checkStudyBefore, enrollInfo } from "@/api/home/guild";
import detailModal from "@/views/cyry/components/xx/detail.vue";

export default {
  name: "home",
  components: {
    detailModal,
    ksLogin,
  },
  data() {
    return {
      total: 0,
      params: {
        pageNo: 1,
        pageSize: 10,
      },
      form: {},
      ryList: [],
      userInfo: {},
      typeName: "",
      catid: "",
      loading: false,
    };
  },
  created() {
    this.getArticle();
  },
  methods: {
    showInfo(obj) {
      console.log("===========obj=============", obj);
      this.showText = this.formatStatus(obj);
      this.showPop = true;
    },
    formatStatus(obj) {
      //console.log("=======formatStatus========", obj, typeof(obj.status))
      if (obj.status == 1) return "审核通过";
      if (obj.status == 2)
        return "审核不通过" + (obj.otherinfo ? "|" + obj.otherinfo : "");
      return "待审核";
    },
    changePage(e) {
      this.params.pageNo = e;
      this.getArticle();
    },
    getArticle() {
      this.loading = true;
      enrollInfo({
        ...this.params,
        token: this.$Cookies.get("cyry_token"),
      }).then((res) => {
        if (res.code == 200 || res.code == 0) {
          let info = res.result.records;
          this.ryList = info;
          this.total = res.result.total;
        } else if (res.code == 500) {
          this.$Modal.error({
            title: "错误",
            content: res.message,
            onOk: () => {
              this.$Cookies.remove("cyry_user");
              this.$Cookies.remove("cyry_token");
              this.$refs["ksbox"].showLogin = true;
            },
          });
        }
        this.loading = false;
      });
    },
    toAdd() {
      this.$refs["detailModal"].showPop = true;
      this.$refs["detailModal"].id = "";
    },
    toReset() {
      this.form = {};
      this.params.pageNo = 1;
      this.getArticle();
    },
    goDetail(it) {
      // this.$refs["detailModal"].showPop = true;
      // this.$refs["detailModal"].id = it.id;
      let cyry_token = this.$Cookies.get("cyry_token");
      if (!cyry_token) {
        this.$Modal.confirm({
          title: "提示",
          content: "未登录，请登录从业人员信息后开始学习。",
          okText: "去登录",
          onOk: () => {
            this.$refs["ksbox"].showLogin = true;
          },
        });
        return;
      }
      checkStudyBefore({
        courseId: it.courseId,
        token: this.$Cookies.get("cyry_token"),
      })
        .then((res) => {
          if (res.code == 200) {
            let url = "subjectDetail";
            this.$router.push({
              name: url,
              query: {
                id: it.courseId,
                name: encodeURIComponent("公益讲座"),
              },
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ivu-tabs-nav .ivu-tabs-tab {
  width: 200px;
  text-align: center;
  font-size: 18px;
}

.tz_box {
  margin: 10px 20px;
  // width: 100%;
  // max-width: 1000px;

  .search_box {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 20px 0;

    > div {
      margin: 10px;
      white-space: nowrap;
      cursor: pointer;
    }

    .btn1 {
      background: linear-gradient(270deg, #2d85e2 2%, #5b43df 100%);
      color: #fff;
      padding: 5px 15px;
    }

    .btn2 {
      background: #767676;
      color: #fff;
      padding: 5px 15px;
    }
  }

  .cell_box {
    margin-top: 20px;
  }
}
</style>
