var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('headerBox',{attrs:{"current":6}}),_c('bannerBox',{ref:"bannerChild"}),_c('div',{staticClass:"content_box_kc"},[_c('div',{staticClass:"layout"},[_c('div',{staticClass:"layout-content"},[_c('Row',[_c('i-col',{attrs:{"span":"5"}},[(_vm.subjectInfo.items.length > 0)?_c('Menu',{attrs:{"active-name":_vm.subCurrent,"width":"auto"},on:{"on-select":_vm.chooseMenu}},[_c('div',{staticClass:"layout-logo-left"}),_vm._l((_vm.subjectInfo.items),function(it,i){return _c('Menu-item',{key:i,attrs:{"name":i}},[_c('span',{staticClass:"layout-text"},[_vm._v(_vm._s(it.title))])])})],2):_vm._e()],1),_c('i-col',{attrs:{"span":"19"}},[(_vm.subjectInfo.items.length > 0)?_c('div',{staticClass:"item_box"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"flex_item"},[_c('div',{staticClass:"tit_box"},[_c('div',{staticStyle:{"align-items":"center"}},[_c('img',{staticStyle:{"height":"100px","object-fit":"cover","margin-right":"15px"},attrs:{"src":_vm.subjectInfo.items[_vm.subCurrent].banner}}),_c('div',[(
                            _vm.subjectInfo.items[_vm.subCurrent].attachType == 'PPT'
                          )?_c('span',{staticClass:"iconfont icon-ppt",staticStyle:{"color":"#d24625"}}):_vm._e(),(
                            _vm.subjectInfo.items[_vm.subCurrent].attachType == 'PDF'
                          )?_c('span',{staticClass:"iconfont icon-PDF",staticStyle:{"color":"#e45252"}}):_vm._e(),(
                            _vm.subjectInfo.items[_vm.subCurrent].attachType == 'WORD'
                          )?_c('span',{staticClass:"iconfont icon-word",staticStyle:{"color":"#41a5ee"}}):_vm._e(),(
                            _vm.subjectInfo.items[_vm.subCurrent].attachType ==
                            'EXCEL'
                          )?_c('span',{staticClass:"iconfont icon-excel-full",staticStyle:{"color":"#207346"}}):_vm._e(),(
                            _vm.subjectInfo.items[_vm.subCurrent].attachType == '图片'
                          )?_c('span',{staticClass:"iconfont icon-tupian",staticStyle:{"color":"#148bd5"}}):_vm._e(),(
                            _vm.subjectInfo.items[_vm.subCurrent].attachType == '视频'
                          )?_c('span',{staticClass:"iconfont icon-shipin",staticStyle:{"color":"#6467f0"}}):_vm._e(),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.subjectInfo.items[_vm.subCurrent].title)+" ")])])]),_c('div',{staticClass:"kc_info"},[_c('span',{staticClass:"tit_bold"},[_vm._v("授课老师：")]),_c('span',[_vm._v(_vm._s(_vm.subjectInfo.items[_vm.subCurrent].teacher))]),_c('span',{staticStyle:{"margin":"0 10px"}},[_vm._v("|")]),_c('span',{staticClass:"tit_bold"},[_vm._v("课程学时：")]),_c('span',[_vm._v(_vm._s(_vm.subjectInfo.items[_vm.subCurrent].classHour)+"学时")]),_c('span',{staticStyle:{"margin":"0 10px"}},[_vm._v("|")]),_c('span',{staticClass:"tit_bold"},[_vm._v("学习时长：")]),_c('span',[_vm._v(_vm._s(_vm.subjectInfo.items[_vm.subCurrent].studyTimes)+"分钟")])]),_c('div',[_c('span',{staticClass:"tit_bold"},[_vm._v("老师简介：")]),_c('span',[_vm._v(_vm._s(_vm.subjectInfo.items[_vm.subCurrent].teacherDesc))])])]),_c('div',{staticClass:"xx_box"},[_c('Progress',{staticStyle:{"width":"130px"},attrs:{"percent":_vm.subjectInfo.items[_vm.subCurrent].percent,"status":_vm.subjectInfo.items[_vm.subCurrent].percent >= 60
                          ? 'success'
                          : _vm.subjectInfo.items[_vm.subCurrent].study_status,"stroke-width":5}}),_c('div',{staticClass:"xx_btn",on:{"click":function($event){return _vm.goxx(_vm.subjectInfo.items[_vm.subCurrent])}}},[_vm._v(" 去学习 ")])],1)])])]):_vm._e(),_c('div',{staticClass:"btn_box"},[_c('div',{on:{"click":_vm.goBack}},[_vm._v("返回课程")]),(_vm.subjectInfo.examine == 1)?_c('div',{staticStyle:{"background-color":"seagreen"},style:({
                  opacity: _vm.subjectInfo.studyFinish == 1 ? '1' : '.5',
                }),on:{"click":function($event){return _vm.goKS(_vm.subjectInfo, _vm.subjectInfo.studyFinish)}}},[_vm._v(" 参加考试 ")]):_vm._e()])])],1)],1)])]),_c('fullScreen',{ref:"fullScreen",attrs:{"subCurrent":_vm.subCurrent},on:{"closeModal":_vm.closeModal}}),_c('ksLogin',{ref:"ksbox"}),_c('footerBox'),_c('Back-top',{attrs:{"bottom":120,"height":100}},[_c('div',{staticClass:"iconfont icon-shangjiantou"})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }