<template>
  <div class="tz_box">
    <Tabs value="name1">
      <Tab-pane label="公益讲座" name="name1">
        <div class="cell_box">
          <el-table
            :cell-style="{ padding: 0 }"
            :data="ryList"
            :header-cell-style="{
              lineHeight: '35px',
              height: '35px',
              padding: 0,
              backgroundColor: '#49b3ff',
              color: '#515a6e',
            }"
            stripe
            style="width: 100%"
          >
            <el-table-column
              align="center"
              label="序号"
              type="index"
              width="80"
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="课程名称"
              prop="name"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="课程学时"
              prop="classHour"
              show-overflow-tooltip
              width="150"
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="学习方式"
              prop="name"
              show-overflow-tooltip
              width="150"
            >
              <template #default="scope">
                {{
                  scope.row.study == 0
                    ? "线下"
                    : scope.row.study == 1
                    ? "线上"
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="培训人数"
              prop="classNum"
              show-overflow-tooltip
              width="150"
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="课程简介"
              prop="description"
              show-overflow-tooltip
              width="250"
            >
            </el-table-column>

            <el-table-column align="center" label="操作" width="150">
              <template #default="scope">
                <el-button disabled type="text" @click="goDetail(scope.row)"
                  >立即学习</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <Page
          v-if="ryList.length != 0"
          :current="params.pageNo"
          :page-size="params.pageSize"
          :total="total"
          class="common_page_box"
          show-total
          @on-change="changePage"
        ></Page>
      </Tab-pane>
    </Tabs>
    <vipLogin ref="vipbox"></vipLogin>
  </div>
</template>

<script>
import vipLogin from "@/components/vip_login.vue";
import { courseList } from "@/api/home/guild";
export default {
  name: "home",
  components: {
    vipLogin,
  },
  data() {
    return {
      total: 0,
      params: {
        pageNo: 1,
        pageSize: 10,
      },
      form: {
        categary: 1,
      },
      ryList: [],
    };
  },
  mounted() {
    this.getArticle();
  },
  methods: {
    changePage(e) {
      this.params.pageNo = e;
      this.getArticle();
    },
    getArticle() {
      courseList({ ...this.params, ...this.form }).then((res) => {
        if (res.code == 200 || res.code == 0) {
          let info = res.result.records;
          this.ryList = info;
          this.total = res.result.total;
        } else if (res.code == 401) {
          this.$refs["vipbox"].showLogin = true;
        }
      });
    },
    toSearch() {
      this.params.pageNo = 1;
      this.getArticle();
    },
    toReset() {
      this.form = {};
      this.params.pageNo = 1;
      this.getArticle();
    },
    goDetail(it) {
      // this.$refs["detailModal"].showPop = true;
      // this.$refs["detailModal"].id = it.id;
      let url = "subjectDetail";
      this.$router.push({
        name: url,
        query: {
          id: it.id,
          name: encodeURIComponent("公益讲座"),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ivu-tabs-nav .ivu-tabs-tab {
  width: 200px;
  text-align: center;
  font-size: 18px;
}

.tz_box {
  margin: 10px 20px;
  // width: 100%;
  // max-width: 1000px;

  .search_box {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 20px 0;
    > div {
      margin: 10px;
      white-space: nowrap;
      cursor: pointer;
    }
    .btn1 {
      background: linear-gradient(270deg, #2d85e2 2%, #5b43df 100%);
      color: #fff;
      padding: 5px 15px;
    }
    .btn2 {
      background: #767676;
      color: #fff;
      padding: 5px 15px;
    }
  }

  .cell_box {
    margin-top: 20px;

    .cell {
      display: flex;
      width: 100%;
      white-space: nowrap;
      font-size: 15px;
      padding: 10px 0;
      text-align: center;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;

      > div:first-child {
        width: 10%;
      }

      > div:nth-child(2) {
        width: 55%;
      }

      > div:nth-child(3) {
        width: 20%;
      }

      > div:nth-child(4) {
        width: 15%;
      }
    }

    .th {
      background-color: #49b3ff;
    }
    .td {
      // cursor: pointer;
    }
    .td:hover {
      background-color: #eee;
    }
    .td:nth-child(odd) {
      background-color: #d8d8d8;
    }
    .td:nth-child(even) {
      border-bottom: 1px solid #eee;
    }
  }
}
</style>
