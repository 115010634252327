var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tz_box"},[_c('div',{staticClass:"downLoadBtn",on:{"click":_vm.download}},[_c('span',[_vm._v("下载培训记录")]),_c('span',{staticClass:"iconfont icon-xiazai"})]),_c('div',{staticClass:"cell_box"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"cell-style":{ padding: 0 },"data":_vm.ryList,"header-cell-style":{
        lineHeight: '35px',
        height: '35px',
        padding: 0,
        backgroundColor: '#49b3ff',
        color: '#515a6e',
      }}},[_c('el-table-column',{attrs:{"align":"center","label":"序号","type":"index","width":"100"}}),_c('el-table-column',{attrs:{"align":"center","label":"课程ID","prop":"courseId","show-overflow-tooltip":"","width":"200px"}}),_c('el-table-column',{attrs:{"align":"center","label":"培训课程","prop":"courseName","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"align":"center","label":"学习时间","prop":"updateTime","show-overflow-tooltip":"","width":"200px"}}),_c('el-table-column',{attrs:{"align":"center","label":"已获学时","prop":"classHour","show-overflow-tooltip":"","width":"200px"}}),_c('el-table-column',{attrs:{"align":"center","fixed":"right","label":"操作","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.lookDetail(scope.row)}}},[_vm._v(" 查看详情 ")])]}}])})],1)],1),(_vm.ryList.length != 0)?_c('Page',{staticClass:"common_page_box",attrs:{"current":_vm.params.pageNo,"page-size":_vm.params.pageSize,"total":_vm.total,"show-total":""},on:{"on-change":_vm.changePage}}):_vm._e(),_c('ksLogin',{ref:"ksbox"}),_c('detailModal',{ref:"detailModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }