import axios from "axios";
import Vue from "vue";
import Cookies from "js-cookie";
// import store from '@/store'
// import {
// 	getToken
// } from '@/utils/auth'
import { Modal } from "iview";

axios.defaults.headers["Content-Type"] = "application/json;charset=UTF-8";
// 创建axios实例

// 接口地址
// var host = "http://172.20.0.15:8089";//何
// var host = "http://172.20.0.108:8089";//测试
// var host = "http://172.20.0.64:8089";//王
// var host = "http://172.17.0.92:8089"; //严
// var host = "http://172.20.0.100:8089"; //吴
var host = "http://36.138.114.21:8086"; //生产

axios.defaults.baseURL = host;
Vue.prototype.$host = host; //接口地址
Vue.prototype.$imgHost =
  "http://36.138.114.21:8086" + "/jeecg-boot/webfile/upfile"; // 上传图片地址
Vue.prototype.$imgCommonHost =
  "http://36.138.114.21:8086" + "/jeecg-boot/webfile/uppublicfile"; // 上传图片地址
Vue.prototype.$previewImg = "http://36.138.114.21:8086" + "/file_resources/"; // 查看图片地址 生产
// Vue.prototype.$sypcHost = "http://172.20.0.108:8082" + "/file_resources/"; // 首页飘窗-dev
Vue.prototype.$sypcHost = "http://36.138.114.21:8082" + "/file_resources/"; // 首页飘窗-生产
// Vue.prototype.$previewImg = "http://172.20.0.108:8082" + "/file_resources/"; // 查看图片地址 严杰

// 考试系统
Vue.prototype.$ksHost = "http://www.gzztbxh.com/gzztbxhks"; // 考试系统跳转 生产
// Vue.prototype.$ksHost = "http://172.17.0.90:5174/gzztbxhks"; // 考试系统跳转 本地
const service = axios.create({
  // 超时
  timeout: 60000,
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    // const isToken = (config.headers || {}).isToken === false
    // const token = JSON.parse(Cookies.get('xh_userInfo')).token;
    // if (token && !isToken) {
    // 	config.headers['X-Access-Token'] = token
    // }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  },
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const message = res.data.message;
    if (code == 200 || code == 311) {
      return res.data;
    }
    if (code === 401 || code === 444) {
      Cookies.remove("xh_userInfo");
      return res.data;
    } else if (code === 500 || code === 411) {
      console.log(9999, message);
      Modal.error({
        title: "错误",
        content: message,
      });
      return res.data;
    } else {
      Modal.error({
        title: "错误",
        content: message,
      });
      return res.data;
    }
  },
  (error) => {
    console.log(error);
    // if (error) {
    //     return Promise.reject(error)
    // }
    Modal.error({
      title: "错误",
      content: "服务异常！请联系管理员",
    });
    return Promise.reject(error);
  },
);

export default service;
