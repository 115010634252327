<template>
  <div>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="details.dialogVisible"
      title="选择人员"
      width="50%"
    >
      <div class="content-box">
        <el-table
          ref="multipleTable"
          :data="tableData"
          style="width: 100%"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
        >
          <el-table-column :selectable="selectable" type="selection" width="55">
          </el-table-column>
          <el-table-column align="center" label="序号" type="index" width="60">
          </el-table-column>
          <el-table-column
            align="center"
            label="姓名"
            prop="name"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            label="手机号"
            prop="phone"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            label="身份证号"
            prop="idCard"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            label="报名状态"
            prop="isEnroll"
            show-overflow-tooltip
          >
            <template #default="scope">
              <span>
                {{ scope.row.isEnroll == 0 ? "未报名" : "已报名" }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          :disabled="!(multipleSelection.length > 0)"
          type="primary"
          @click="submitBtn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDwRyInfo, batchCourseEnroll } from "@/api/home/basicInfo";

export default {
  props: ["details"],
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      xh_userInfo: {},
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    handleSelectionChange(val) {
      console.log("val", val);
      this.multipleSelection = val;
    },
    selectable(row, index) {
      return row.isEnroll == 0;
    },
    initData() {
      this.xh_userInfo = JSON.parse(this.$Cookies.get("xh_userInfo"));
      let params = {
        courseId: this.details.row.id,
        dwmc: this.xh_userInfo.user.dwmc,
        studyObj: this.details.row.studyObj,
        token: this.xh_userInfo.token,
      };
      getDwRyInfo(params).then((res) => {
        this.tableData = res.result;
      });
    },
    handleClose() {
      this.$emit("handleClose");
    },
    submitBtn() {
      let studentIds =
        this.multipleSelection.length > 0
          ? this.multipleSelection.map((item) => item.studentId).join(",")
          : "";
      batchCourseEnroll({
        courseId: this.details.row.id,
        studentIds: studentIds,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
        }
        this.$emit("submitBtn");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-box {
  display: flex;
  justify-content: center;
}
</style>
