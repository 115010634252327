<template>
  <div class="tz_box">
    <Tabs value="name1">
      <Tab-pane label="已报课程" name="name1">
        <div class="search_box">
          <div>
            培训课程：<Input
              v-model="form.courseName"
              placeholder=""
              style="width: 180px"
            ></Input>
          </div>
          <div>
            姓名：<Input
              v-model="form.name"
              placeholder=""
              style="width: 180px"
            ></Input>
          </div>
          <div>
            报名状态：<Select v-model="form.confirm" style="width: 200px">
              <Option
                v-for="item in confirmList"
                :key="item.value"
                :value="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </div>
          <div class="btn1" @click="toSearch">查询</div>
          <div class="btn2" @click="toReset">重置</div>
        </div>
        <div class="cell_box">
          <el-table
            :cell-style="{ padding: '10px' }"
            :data="ryList"
            :header-cell-style="{
              lineHeight: '35px',
              height: '35px',
              padding: 0,
              backgroundColor: '#49b3ff',
              color: '#515a6e',
            }"
            stripe
            style="width: 100%"
          >
            <el-table-column
              align="center"
              label="序号"
              type="index"
              width="80"
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="培训课程"
              prop="courseName"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="报名状态"
              prop="confirm"
              show-overflow-tooltip
              width="150"
            >
              <template #default="scope">
                <span>
                  {{ scope.row.confirm == 0 ? "待缴费" : "缴费成功" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="报名人员"
              prop="name"
              show-overflow-tooltip
              width="150"
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="电话"
              prop="phone"
              show-overflow-tooltip
              width="150"
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="身份证号"
              prop="idcard"
              show-overflow-tooltip
              width="200"
            >
            </el-table-column>
            <!--            <el-table-column align="center" label="操作" width="150">-->
            <!--              <template #default="scope">-->
            <!--                <el-button disabled type="text" @click="goDetail(scope.row)"-->
            <!--                  >查看详情</el-button-->
            <!--                >-->
            <!--              </template>-->
            <!--            </el-table-column>-->
          </el-table>
        </div>
        <Page
          v-if="ryList.length > 0"
          :current="params.pageNo"
          :page-size="params.pageSize"
          :total="total"
          class="common_page_box"
          show-total
          @on-change="changePage"
        ></Page>
      </Tab-pane>
    </Tabs>
    <vipLogin ref="vipbox"></vipLogin>
  </div>
</template>

<script>
import vipLogin from "@/components/vip_login.vue";
import { enrollCourseList } from "@/api/home/guild";
export default {
  name: "home",
  components: {
    vipLogin,
  },
  data() {
    return {
      total: 0,
      params: {
        pageNo: 1,
        pageSize: 10,
      },
      form: {
        company: "",
        courseName: "",
        name: "",
        confirm: "",
      },
      ryList: [],
      confirmList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "待缴费",
          value: 0,
        },
        {
          label: "缴费成功",
          value: 1,
        },
      ],
    };
  },
  mounted() {
    this.getArticle();
  },
  methods: {
    changePage(e) {
      this.params.pageNo = e;
      this.getArticle();
    },
    getArticle() {
      let xh_userInfo = this.$Cookies.get("xh_userInfo")
        ? JSON.parse(this.$Cookies.get("xh_userInfo"))
        : "";
      if (!xh_userInfo && !xh_userInfo.token) {
        this.$refs["vipbox"].showLogin = true;
        return;
      }
      this.form.company = xh_userInfo.user.dwmc;
      enrollCourseList({
        ...this.params,
        ...this.form,
        token: xh_userInfo.token,
      }).then((res) => {
        if (res.code == 200 || res.code == 0) {
          let info = res.result.records;
          this.ryList = info;
          this.total = res.result.total;
        } else if (res.code == 401) {
          this.$refs["vipbox"].showLogin = true;
        }
      });
    },
    toSearch() {
      this.params.pageNo = 1;
      this.getArticle();
    },
    toReset() {
      this.form = {};
      this.params.pageNo = 1;
      this.getArticle();
    },
    goDetail(it) {
      // this.$refs["detailModal"].showPop = true;
      // this.$refs["detailModal"].id = it.id;
      // let url = "subjectDetail";
      // this.$router.push({
      //   name: url,
      //   query: {
      //     id: it.id,
      //     name: encodeURIComponent("公益讲座"),
      //   },
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ivu-tabs-nav .ivu-tabs-tab {
  width: 200px;
  text-align: center;
  font-size: 18px;
}

.tz_box {
  margin: 10px 20px;
  // width: 100%;
  // max-width: 1000px;

  .search_box {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 20px 0;
    > div {
      margin: 10px;
      white-space: nowrap;
      cursor: pointer;
    }
    .btn1 {
      background: linear-gradient(270deg, #2d85e2 2%, #5b43df 100%);
      color: #fff;
      padding: 5px 15px;
    }
    .btn2 {
      background: #767676;
      color: #fff;
      padding: 5px 15px;
    }
  }

  .cell_box {
    margin-top: 20px;

    .cell {
      display: flex;
      width: 100%;
      white-space: nowrap;
      font-size: 15px;
      padding: 10px 0;
      text-align: center;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;

      > div:first-child {
        width: 10%;
      }

      > div:nth-child(2) {
        width: 55%;
      }

      > div:nth-child(3) {
        width: 20%;
      }

      > div:nth-child(4) {
        width: 15%;
      }
    }

    .th {
      background-color: #49b3ff;
    }
    .td {
      // cursor: pointer;
    }
    .td:hover {
      background-color: #eee;
    }
    .td:nth-child(odd) {
      background-color: #d8d8d8;
    }
    .td:nth-child(even) {
      border-bottom: 1px solid #eee;
    }
  }
}
</style>
