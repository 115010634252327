<template>
  <div class="box1">
    <div v-if="newList.length > 0" class="img">
      <el-carousel height="300px">
        <el-carousel-item v-for="item in carouselList" :key="item.id">
          <div @click="goDetail(item.catid)">
            <img v-if="item.thumb" :src="item.thumb" alt="/" />
            <img v-else alt="/" src="@/assets/images/defaultImg.jpg" />
            <div class="img_text">
              <div class="title single">{{ item.title }}</div>
              <!--              <div class="content">{{ item.description }}</div>-->
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>

      <!--      <img v-if="leftInfo.thumb" :src="leftInfo.thumb" alt="/" />-->
      <!--      <img v-else alt="/" src="@/assets/images/defaultImg.jpg" />-->
      <!--      <div class="img_text">-->
      <!--        <div class="title single">{{ leftInfo.title }}</div>-->
      <!--        <div class="content">{{ leftInfo.description }}111</div>-->
      <!--      </div>-->
    </div>
    <div class="tab_box">
      <div class="tab_tit">
        <div
          v-for="(it, i) in tabList"
          :key="i"
          :class="current == i ? 'selected item' : 'noSelected item'"
          @click="getTab(i)"
          @mouseover="getTab(i)"
        >
          {{ it.name }}
        </div>
      </div>
      <div class="box1_height">
        <div v-if="newList.length > 0">
          <div
            v-for="(tt, ii) in newList"
            :key="ii"
            class="box1_content"
            @click="goDetail(tt.id)"
          >
            <div class="tag_line"></div>
            <div class="text single">{{ tt.title }}</div>
            <div>{{ tt.date }}</div>
          </div>
          <div
            class="common_more"
            style="float: right; margin-top: 10px"
            @click="goDetail()"
          >
            <span>更多</span>
            <span class="iconfont icon-gengduo"></span>
          </div>
        </div>
        <div
          v-if="newList.length == 0"
          class="common_no_more"
          style="height: 248px"
        >
          暂无内容~
        </div>
      </div>
    </div>
    <!-- <div class="tab_box" v-if="tabList.length>0">
			<Tabs :value="tabList[current].catid" @on-click="getTab">
				<Tab-pane v-for="(it,i) in tabList" :key="i" :label="it.name" :name="it.catid">
					<div class="box1_height">
						<div v-if="newList.length>0">
							<div class="box1_content" v-for="(tt,ii) in newList" :key="ii" @click="goDetail(it,tt.id)">
								<div class="tag_line"></div>
								<div class="text single">{{tt.title}}</div>
								<div>{{tt.date}}</div>
							</div>
							<div @click="goDetail(tabList[current])" class="common_more"
								style="float: right;margin-top: 10px;">
								<span>更多</span>
								<span class="iconfont icon-gengduo"></span>
							</div>
						</div>
						<div class="common_no_more" style="height: 248px" v-if="newList.length==0">暂无内容~</div>
					</div>
				</Tab-pane>
			</Tabs>
		</div> -->
  </div>
</template>
<script>
import { getArticleDes, getArticleDesSylbApi } from "@/api/home/index";
export default {
  props: {
    isBlue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      current: 2,
      tabList: [
        {
          name: "行业要闻",
          catid: "6",
          url: "news",
        },
        {
          name: "协会动态",
          catid: "91",
          url: "news",
        },
        {
          name: "通知公告",
          catid: "10",
          url: "news",
        },
        {
          name: "党建活动",
          catid: "153",
          url: "party",
        },
      ],
      newList: [],
      leftInfo: {},
      carouselList: [],
    };
  },
  mounted() {
    let catid = this.tabList[this.current].catid;
    this.getArticleDes(catid);
    this.getArticleDesSylb();
  },
  methods: {
    getArticleDes(catid) {
      getArticleDes({
        catid,
        pageNo: 1,
        pageSize: 4,
      }).then((res) => {
        if (res.code == 200 || res.code == 0) {
          this.newList = res.result.records;
          console.log("newList", this.newList);
          // if(catid==10){//通知公告
          // this.leftInfo = res.result.records[0];
          // }
        }
      });
    },

    // 查询轮播图片数据
    getArticleDesSylb() {
      getArticleDesSylbApi().then((res) => {
        if (res.code === 200) {
          this.carouselList = res.result;
        }
        console.log("res", this.carouselList);
      });
    },

    // getTab(e) {
    // 	this.current = this.tabList.findIndex(v => v.catid == e)
    // 	this.getArticleDes(e);
    // },
    getTab(e) {
      this.current = e;
      this.getArticleDes(this.tabList[e].catid);
    },
    goDetail(e) {
      let url = "";
      let query = {};
      let params = {};
      if (e) {
        url = "news_detail";
        query = {
          id: e,
        };
        params = {
          locationList: encodeURIComponent(
            JSON.stringify([this.tabList[this.current].name]),
          ),
        };
      } else {
        url = this.tabList[this.current].url;
        query = {
          name: this.tabList[this.current].name,
        };
      }
      this.$router.push({
        name: url,
        query: query,
        params: params,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.box1 {
  display: flex;
  height: 300px;

  .img {
    width: 55%;
    margin-right: 70px;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    img {
      width: 100%;
      // height: 100%;
      // object-fit: contain;
    }

    .img_text {
      width: 100%;
      position: absolute;
      bottom: 0;
      color: #fff;
      background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);

      .title {
        margin: 20px 15px;
        font-size: 16px;
      }

      .content {
        margin: 10px 15px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -o-box-orient: vertical;
      }
    }
    /deep/ .el-carousel__indicators--horizontal {
      bottom: 0;
      right: 10px;
      transform: translateX(0%);
      display: flex;
      justify-content: flex-end;
    }
  }

  .tab_box {
    width: 45%;
    overflow: hidden;
    .tab_tit {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 17px;
      white-space: nowrap;

      .item {
        // margin: 0 20px;
        cursor: pointer;
        width: 20%;
        height: 35px;
        //padding: 5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .selected {
        border: none;
        color: #fff;
        background: linear-gradient(270deg, #2d85e2 2%, #5b43df 100%);
        box-sizing: border-box;
      }

      .noSelected:hover {
        color: #2d85e2;
        box-sizing: border-box;
      }
    }

    /deep/ .ivu-tabs {
      .ivu-tabs-nav-scroll {
        display: flex;
      }

      .ivu-tabs-nav-container {
        font-size: 15px !important;
        padding: 0;
      }

      .ivu-tabs-bar {
        border: none;
        margin: 0;
      }

      .ivu-tabs-nav .ivu-tabs-tab-active {
        border: none;
        color: #fff;
        background: linear-gradient(270deg, #2d85e2 2%, #5b43df 100%);
      }

      .ivu-tabs-ink-bar {
        height: 0;
      }
    }

    .box1_height {
      // height: 248px;
      // overflow-y: scroll;
      .box1_content {
        font-size: 14px;
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
        white-space: nowrap;
        padding: 10px 5px;
        margin-top: 20px;
        cursor: pointer;

        .text {
          margin: 0 20px;
          font-size: 15px;
        }

        .tag_line {
          width: 4px;
          height: 19px;
          background: linear-gradient(270deg, #2d85e2 2%, #5b43df 100%);
        }
      }

      .box1_content:hover {
        background-color: #eee;
      }
    }
  }
}

/* 手机 */
@media only screen and (max-width: 800px) {
  .box1 {
    display: inline-block;
    width: 100%;

    .img {
      width: 100%;
      margin: 0;
      margin-bottom: 30px;
      height: 150px;
    }

    .tab_box {
      width: 100%;
    }
    .box1_content {
      margin-top: 0 !important;
      padding: 15px 5px !important;
    }
  }
}
</style>
