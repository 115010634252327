<template>
  <div class="tz_box">
    <Tabs value="name1">
      <Tab-pane label="通知" name="name1">
        <!-- <div class="search_box">
					<div>会议主题：<Input v-model="form.title" placeholder="" style="width: 180px"></Input></div>
					<div class="btn1" @click="toSearch">查询</div>
					<div class="btn2" @click="toReset">重置</div>
				</div> -->
        <div class="cell_box">
          <div class="cell th">
            <div v-for="(it, i) in th" :key="i">{{ it }}</div>
          </div>
          <div
            v-for="(it, i) in ryList"
            v-if="ryList.length > 0"
            :key="i"
            class="cell td"
          >
            <div>
              {{ params.pageSize * params.pageNo - params.pageSize + i + 1 }}
            </div>
            <div class="single">{{ it.title }}</div>
            <!-- <div class="single">{{it.content}}</div> -->
            <div class="single">{{ it.date }}</div>
            <div class="single" style="cursor: pointer" @click="goDetail(it)">
              查看
            </div>
          </div>
          <div v-if="ryList.length == 0" class="common_excel_no_more">
            暂无内容~
          </div>
        </div>
        <Page
          v-if="ryList.length != 0"
          :current="params.pageNo"
          :page-size="params.pageSize"
          :total="total"
          class="common_page_box"
          show-total
          @on-change="changePage"
        ></Page>
      </Tab-pane>
    </Tabs>
    <vipLogin ref="vipbox"></vipLogin>
    <detailModal ref="detailModal"></detailModal>
  </div>
</template>

<script>
import bannerBox from "@/components/banner.vue";
import headerBox from "@/components/header.vue";
import footerBox from "@/components/footer.vue";
import vipLogin from "@/components/vip_login.vue";
import detailModal from "./detail.vue";
import { getArticle } from "@/api/home/index";
import { handleIdCard } from "@/utils/util";
export default {
  name: "home",
  components: {
    bannerBox,
    headerBox,
    footerBox,
    vipLogin,
    detailModal,
  },
  data() {
    return {
      th: ["序号", "会议主题", "创建日期", "详情"],
      total: 0,
      params: {
        pageNo: 1,
        pageSize: 10,
      },
      form: {},
      ryList: [],
      xh_userInfo: {},
    };
  },
  mounted() {
    this.getArticle();
    if (this.$Cookies.get("xh_userInfo")) {
      this.xh_userInfo = JSON.parse(this.$Cookies.get("xh_userInfo"));
    }
  },
  methods: {
    changePage(e) {
      this.params.pageNo = e;
      this.getArticle();
    },
    getArticle() {
      getArticle({ ...this.params, ...this.form, catid: 10 }).then((res) => {
        if (res.code == 200 || res.code == 0) {
          let info = res.result.records;
          // if(info.length>0){
          // 	info.map(v=>{
          // 		v.sfz = handleIdCard(v.sfz);
          // 	})
          // }
          this.ryList = info;
          this.total = res.result.total;
        } else if (res.code == 401) {
          this.$refs["vipbox"].showLogin = true;
        }
      });
    },
    toSearch() {
      this.params.pageNo = 1;
      this.getArticle();
    },
    toReset() {
      this.form = {};
      this.params.pageNo = 1;
      this.getArticle();
    },
    goDetail(it) {
      // expireFlag 会员是否失效：有效：0，已过期：1
      if (this.xh_userInfo.user.expireFlag === "1") {
        this.$Modal.confirm({
          title: "提示",
          content:
            "未缴纳会费，请与协会联系，联系电话：0851-86587786  联系人：丁筑晓",
          cancelText: "关闭",
          okText: "以非会员的价格报名",
        });
        // return;
      }
      this.$refs["detailModal"].showPop = true;
      this.$refs["detailModal"].id = it.id;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ivu-tabs-nav .ivu-tabs-tab {
  width: 200px;
  text-align: center;
  font-size: 18px;
}

.tz_box {
  margin: 10px 20px;
  // width: 100%;
  // max-width: 1000px;

  .search_box {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 20px 0;
    > div {
      margin: 10px;
      white-space: nowrap;
      cursor: pointer;
    }
    .btn1 {
      background: linear-gradient(270deg, #2d85e2 2%, #5b43df 100%);
      color: #fff;
      padding: 5px 15px;
    }
    .btn2 {
      background: #767676;
      color: #fff;
      padding: 5px 15px;
    }
  }

  .cell_box {
    margin-top: 20px;

    .cell {
      display: flex;
      width: 100%;
      white-space: nowrap;
      font-size: 15px;
      padding: 10px 0;
      text-align: center;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;

      > div:first-child {
        width: 10%;
      }

      > div:nth-child(2) {
        width: 55%;
      }

      > div:nth-child(3) {
        width: 20%;
      }

      > div:nth-child(4) {
        width: 15%;
      }
    }

    .th {
      background-color: #49b3ff;
    }
    .td {
      // cursor: pointer;
    }
    .td:hover {
      background-color: #eee;
    }
    .td:nth-child(odd) {
      background-color: #d8d8d8;
    }
    .td:nth-child(even) {
      border-bottom: 1px solid #eee;
    }
  }
}
</style>
