<template>
  <div class="tz_box">
    <Tabs value="name1">
      <Tab-pane label="课程中心" name="name1">
        <!-- <div class="search_box">
					<div>会议主题：<Input v-model="form.title" placeholder="" style="width: 180px"></Input></div>
					<div class="btn1" @click="toSearch">查询</div>
					<div class="btn2" @click="toReset">重置</div>
				</div> -->
        <div class="cell_box">
          <el-table
            v-loading="loading"
            :cell-style="{ padding: 0 }"
            :data="enrollsData"
            :header-cell-style="{
              lineHeight: '35px',
              height: '35px',
              padding: 0,
              backgroundColor: '#49b3ff',
              color: '#515a6e',
            }"
            style="width: 100%"
          >
            <el-table-column
              align="center"
              label="序号"
              type="index"
              width="80"
            ></el-table-column>
            <el-table-column
              align="center"
              label="课程名称"
              prop="name"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              align="center"
              label="课程学时"
              prop="classHour"
              show-overflow-tooltip
              width="150px"
            >
              <template #default="scope">
                {{ scope.row.classHour ? scope.row.classHour + "学时" : "" }}
              </template>
            </el-table-column>
            <!--          <el-table-column-->
            <!--            align="center"-->
            <!--            label="课程简介"-->
            <!--            prop="description"-->
            <!--            show-overflow-tooltip-->
            <!--            width="150px"-->
            <!--          ></el-table-column>-->
            <el-table-column
              align="center"
              label="课程价格"
              prop="classPrice"
              show-overflow-tooltip
              width="150px"
            >
              <template #default="scope">
                {{ scope.row.charge == "0" ? "免费" : scope.row.classPrice }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="学习方式"
              prop="study"
              show-overflow-tooltip
              width="150px"
            >
              <template #default="scope">
                {{
                  scope.row.study == "0"
                    ? "线下"
                    : scope.row.study == "1"
                      ? "线上"
                      : ""
                }}
              </template>
            </el-table-column>
            <!--          <el-table-column-->
            <!--            align="center"-->
            <!--            label="报名状态"-->
            <!--            prop="isEnroll"-->
            <!--            show-overflow-tooltip-->
            <!--            width="100px"-->
            <!--          >-->
            <!--            <template #default="scope">-->
            <!--              {{-->
            <!--                scope.row.isEnroll == "0"-->
            <!--                  ? "待确认"-->
            <!--                  : scope.row.isEnroll == "1"-->
            <!--                  ? "已报名"-->
            <!--                  : "未报名"-->
            <!--              }}-->
            <!--            </template>-->
            <!--          </el-table-column>-->
            <!--          <el-table-column align="center" label="学习状态" prop="studyFinish" show-overflow-tooltip width="120px">-->
            <!--            <template #default="scope">-->
            <!--              {{ scope.row.studyFinish == '1' ? '已完成' : scope.row.studyFinish == '0' ? '未学习' : '未学习' }}-->
            <!--            </template>-->
            <!--          </el-table-column>-->
            <el-table-column
              align="center"
              fixed="right"
              label="操作"
              width="180px"
            >
              <template #default="scope">
                <el-button
                  v-if="scope.row.isEnroll == null"
                  type="text"
                  @click="toBm(scope.row)"
                >
                  立即报名
                </el-button>
                <el-button
                  v-show="scope.row.isEnroll !== null"
                  :disabled="scope.row.isEnroll == '0'"
                  type="text"
                  @click="goXX(scope.row)"
                  >立即学习
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <Page
          v-if="enrollsData.length != 0"
          :current="params.pageNo"
          :page-size="params.pageSize"
          :total="total"
          class="common_page_box"
          show-total
          @on-change="changePage"
        ></Page>
      </Tab-pane>
    </Tabs>
    <vipLogin ref="vipbox"></vipLogin>
  </div>
</template>

<script>
import { courseEnroll, cyryCourseList } from "@/api/home/guild";
import vipLogin from "@/components/vip_login.vue";

export default {
  components: { vipLogin },
  data() {
    return {
      cyry_user: {},
      enrollsData: [], // 报名信息
      loading: false,
      total: 0,
      queryType: "web",
      params: {
        pageNo: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {
    if (this.$Cookies.get("cyry_user")) {
      this.cyry_user = JSON.parse(this.$Cookies.get("cyry_user"));
      this.getInfoByCyry();
    }
  },
  methods: {
    goDetail(row) {
      let cyry_token = this.$Cookies.get("cyry_token");
      if (!cyry_token) {
        this.$Modal.confirm({
          title: "提示",
          content: "未登录，请登录从业人员信息后开始学习。",
          okText: "去登录",
          onOk: () => {
            this.$refs["ksbox"].showLogin = true;
          },
        });
        return;
      }
      if (row.isEnroll == 0) {
        this.$Modal.info({
          title: "提示",
          content: "已报名缴费未确认,请在缴费确认后参加学习。",
        });
        return;
      }
      // }
      let url = "subjectDetail";
      this.$router.push({
        name: url,
        query: {
          id: row.id,
          name: encodeURIComponent("公益讲座"),
        },
      });
    },
    // 点击学习 按钮
    goXX(row) {
      let cyry_token = this.$Cookies.get("cyry_token");
      if (!cyry_token) {
        this.$Modal.confirm({
          title: "提示",
          content: "未登录，请登录从业人员信息后开始学习。",
          okText: "去登录",
          onOk: () => {
            this.$refs["ksbox"].showLogin = true;
          },
        });
        return;
      }
      if (row.isEnroll == 0) {
        this.$Modal.info({
          title: "提示",
          content: "已报名缴费未确认,请在缴费确认后参加学习。",
        });
        return;
      }
      let url = "subjectDetail";
      this.$router.push({
        name: url,
        query: {
          id: row.id,
          name: encodeURIComponent("课程中心"),
        },
      });
    },
    // 报名学习 按钮
    toBm(it) {
      console.log(JSON.parse(this.$Cookies.get("cyry_user")));
      let that = this;
      let cyry_token = this.$Cookies.get("cyry_token");
      if (!cyry_token) {
        this.$Modal.confirm({
          title: "温馨提示",
          okText: "登录",
          content: "未登录，请您登录从业人员信息后报名学习",
          onOk: () => {
            this.$refs["ksbox"].showLogin = true;
          },
        });
        return;
      }
      if (it.charge == 0) {
        courseEnroll({
          id: it.id,
          token: that.$Cookies.get("cyry_token"),
        }).then((res) => {
          if (res.code == 200 || res.code == 0) {
            that.$Modal.info({
              title: "提示",
              content: res.message,
              onOk: () => {
                that.getInfoByCyry();
              },
            });
          }
          that.getInfoByCyry();
        });
      } else {
        console.log("that.ishy", that.cyry_user);
        // isMember 非会员：0，有效会员：1，无效会员：2
        if (that.cyry_user.isMember == "0" || that.cyry_user.isMember == "1") {
          that.$Modal.confirm({
            title: "温馨提示",
            width: 600,
            okText: "报名",
            content: `<div style="font-size:16px">
        	尊敬的【${that.cyry_user.name}】用户，该课程为收费课程，请您先缴费后学习！
        	缴费金额：<span style="color:red">${that.cyry_user.isMember == "1" ? "会员价" : "非会员价"}${
            that.cyry_user.isMember == "1" ? it.classPricemember : it.classPrice
          }元</span>，
        	缴费完成后系统将为您开通学习入口，祝您取得佳绩~
        	</div>
        	<br>
        	帐号：52001613600052521882<br>
        	收款单位名称：贵州省建设工程招标投标协会<br>
        	开 户 行：中国建设银行股份有限公司贵阳朝阳支行<br>
        	<br>
        	如您已缴费，系统仍未开通，请拨打0851-86587786咨询。
        	`,
            onOk: () => {
              setTimeout(() => {
                courseEnroll({
                  id: it.id,
                  token: that.$Cookies.get("cyry_token"),
                }).then((res) => {
                  if (res.code == 200 || res.code == 0) {
                    that.$Modal.info({
                      title: "提示",
                      content: res.message,
                      onOk: () => {
                        that.getInfoByCyry();
                      },
                    });
                  }
                });
              }, 300);
            },
          });
        } else if (that.cyry_user.isMember == "2") {
          that.$Modal.confirm({
            title: "提示",
            content:
              "会员过期，请与协会联系，联系电话：0851-86587786  联系人：丁筑晓",
            cancelText: "关闭",
            okText: "以非会员价报名",
            onOk: () => {
              console.log(111);
              setTimeout(() => {
                that.$Modal.confirm({
                  title: "温馨提示",
                  width: 600,
                  okText: "报名",
                  content: `<div style="font-size:16px">
					尊敬的【${that.cyry_user.name}】用户，该课程为收费课程，请您先缴费后学习！
					缴费金额：<span style="color:red">${that.cyry_user.isMember == "1" ? "会员价" : "非会员价"}${
            that.cyry_user.isMember == "1" ? it.classPricemember : it.classPrice
          }元</span>，
					缴费完成后系统将为您开通学习入口，祝您取得佳绩~
					</div>
					<br>
					帐号：52001613600052521882<br>
					收款单位名称：贵州省建设工程招标投标协会<br>
					开 户 行：中国建设银行股份有限公司贵阳朝阳支行<br>
					<br>
					如您已缴费，系统仍未开通，请拨打0851-86587786咨询。
					`,
                  onOk: () => {
                    console.log(2222);

                    setTimeout(() => {
                      courseEnroll({
                        id: it.id,
                        token: that.$Cookies.get("cyry_token"),
                      }).then((res) => {
                        if (res.code == 200 || res.code == 0) {
                          that.$Modal.info({
                            title: "提示",
                            content: res.message,
                            onOk: () => {
                              that.getInfoByCyry();
                            },
                          });
                        }
                      });
                    }, 300);
                  },
                });
              }, 300);
            },
          });
        }
      }
    },
    changePage(e) {
      this.params.pageNo = e;
      this.getInfoByCyry();
    },
    getInfoByCyry() {
      this.loading = true;
      cyryCourseList({
        ...this.params,
        token: this.$Cookies.get("cyry_token"),
      }).then((res) => {
        if (res.code == 200) {
          this.$nextTick(() => {
            this.enrollsData = res.result.records || [];
          });
          this.$forceUpdate();
          this.total = res.result.total;
        }
        this.loading = false;
        console.log("res", res);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ivu-tabs-nav .ivu-tabs-tab {
  width: 200px;
  text-align: center;
  font-size: 18px;
}

.tz_box {
  margin: 10px 20px;
  // width: 100%;
  // max-width: 1000px;

  .search_box {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 20px 0;

    > div {
      margin: 10px;
      white-space: nowrap;
      cursor: pointer;
    }

    .btn1 {
      background: linear-gradient(270deg, #2d85e2 2%, #5b43df 100%);
      color: #fff;
      padding: 5px 15px;
    }

    .btn2 {
      background: #767676;
      color: #fff;
      padding: 5px 15px;
    }
  }

  .cell_box {
    margin-top: 20px;

    .cell {
      display: flex;
      width: 100%;
      white-space: nowrap;
      font-size: 15px;
      padding: 10px 0;
      text-align: center;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;

      > div {
        width: 12%;
      }

      > div:first-child {
        width: 5%;
      }

      > div:nth-child(2) {
        width: 30%;
      }

      > div:nth-child(3) {
        width: 20%;
      }

      > div:nth-child(4) {
        width: 10%;
      }

      > div:nth-child(5) {
        width: 10%;
      }

      > div:nth-child(6) {
        width: 10%;
      }

      > div:nth-child(7) {
        width: 15%;
      }

      > div:nth-child(8) {
        width: 20%;
      }

      //
      //> div:nth-child(9) {
      //  width: 8%;
      //}
      //
      //> div:nth-child(10) {
      //  width: 8%;
      //}
      //
      //> div:nth-child(11) {
      //  width: 8%;
      //}
      //
      //> div:nth-child(12) {
      //  width: 8%;
      //}
      //
      //> div:nth-child(13) {
      //  width: 15%;
      //}
    }

    .th {
      background-color: #49b3ff;
    }

    .td {
      // cursor: pointer;
    }

    .td:hover {
      background-color: #eee;
    }

    .td:nth-child(odd) {
      background-color: #d8d8d8;
    }

    .td:nth-child(even) {
      border-bottom: 1px solid #eee;
    }
  }
}
</style>
