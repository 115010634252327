<!-- 会员证书 -->
<template>
  <div class="item1_box">
    <div v-if="!yxStatus" class="tip">
      <span>系统消息提醒：</span>
      <span class="tip_text"
        >您好，{{
          user.dwmc
        }}，您的会员证书已失效，请您按时缴纳相关费用恢复身份，感谢您的支持！</span
      >
    </div>
    <div style="text-align: center">
      <img
        :src="user.crturl"
        :style="{
          filter: yxStatus ? '' : 'saturate(9%)',
          height: contentHeight,
        }"
      />
    </div>
    <div v-if="!yxStatus" class="mask">
      <div>证书已过期</div>
    </div>
    <!-- <iframe style="height:800px" width=100% :src="`${crturl}`" ></iframe> -->
    <vipLogin ref="vipbox"></vipLogin>
  </div>
</template>

<script>
import vipLogin from "@/components/vip_login.vue";
import { checkVipPeriod } from "@/api/home/index";
export default {
  components: {
    vipLogin,
  },
  data() {
    return {
      user: {},
      yxStatus: true,
      contentHeight: "",
    };
  },
  mounted() {
    this.checkVipPeriod();
    if (this.$Cookies.get("xh_userInfo")) {
      let user = JSON.parse(this.$Cookies.get("xh_userInfo")).user;
      console.log("user", user);
      this.user = user;
    } else {
      this.$refs["vipbox"].showLogin = true;
    }
    this.contentHeight = `calc(${100}vh - 64px - 100px)`;
    // this.$Notice.warning({
    // 	title: '系统消息提醒',
    // 	desc: `您好，${this.user.dwmc}，您的会员证书已失效，请您按时缴纳相关费用恢复身份，感谢您的支持！`,
    // 	duration: 0,
    // });
    // this.$Modal.warning({
    // 	title: '系统消息提醒',
    // 	content: `您好，${this.user.dwmc}，您的会员证书已失效，请您按时缴纳相关费用恢复身份，感谢您的支持！`,
    // });
  },
  methods: {
    checkVipPeriod() {
      checkVipPeriod({}).then((res) => {
        if (res.code == 200 || res.code == 0) {
          this.yxStatus = res.result;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item1_box {
  position: relative;

  .tip {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;

    .tip_text {
      color: red;
      font-weight: normal;
      font-size: 15px;
    }
  }

  img {
    // width: 100%;
    vertical-align: top;
  }

  .mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 50px;
    transform: rotate(-20deg);

    div {
      border: 4px solid #666;
      border-radius: 50%;
      // width: 200px;
      padding: 0 20px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.9;
    }

    // background-color: rgba(0,0,0,.5);
  }
}
</style>
