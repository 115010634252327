var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tz_box"},[_c('Tabs',{attrs:{"value":"name1"}},[_c('Tab-pane',{attrs:{"label":"已报课程","name":"name1"}},[_c('div',{staticClass:"cell_box"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"cell-style":{ padding: 0 },"data":_vm.ryList,"header-cell-style":{
            lineHeight: '35px',
            height: '35px',
            padding: 0,
            backgroundColor: '#49b3ff',
            color: '#515a6e',
          }}},[_c('el-table-column',{attrs:{"align":"center","label":"序号","type":"index","width":"80"}}),_c('el-table-column',{attrs:{"align":"center","label":"报名时间","prop":"enrollTime","show-overflow-tooltip":"","width":"200px"}}),_c('el-table-column',{attrs:{"align":"center","label":"培训课程","prop":"courseName","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"align":"center","label":"报名状态","prop":"confirm","show-overflow-tooltip":"","width":"90px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.confirm == 1 ? "缴费成功" : "待缴费")+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"学习进度","prop":"studyFinish","show-overflow-tooltip":"","width":"90px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.studyFinish == 1 ? "已完成" : "未完成")+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"总学时","prop":"classHourTotal","show-overflow-tooltip":"","width":"90px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.classHourTotal)+"学时 ")]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"考试分数","prop":"studyScore","show-overflow-tooltip":"","width":"90px"}}),_c('el-table-column',{attrs:{"align":"center","fixed":"right","label":"操作","width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"disabled":!(scope.row.confirm == 1),"type":"text"},on:{"click":function($event){return _vm.goDetail(scope.row)}}},[_vm._v(" "+_vm._s(scope.row.studyFinish == 1 ? "重新学习" : "立即学习")+" ")]),(
                  scope.row.confirm === '1' &&
                  scope.row.studyFinish === '1' &&
                  scope.row.examine === '1'
                )?_c('el-button',{attrs:{"disabled":Number(scope.row.studyScore) >= 60,"type":"text"},on:{"click":function($event){return _vm.goKS(scope.row)}}},[_vm._v(" 在线考试 ")]):_vm._e()]}}])})],1)],1),(_vm.ryList.length != 0)?_c('Page',{staticClass:"common_page_box",attrs:{"current":_vm.params.pageNo,"page-size":_vm.params.pageSize,"total":_vm.total,"show-total":""},on:{"on-change":_vm.changePage}}):_vm._e()],1)],1),_c('ksLogin',{ref:"ksbox"}),_c('detailModal',{ref:"detailModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }