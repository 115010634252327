<template>
  <div class="layout">
    <Layout>
      <Header>
        <Menu mode="horizontal" theme="dark">
          <div class="layout-logo">
            <div class="nav_box">
              <span class="iconfont icon-fanhui" @click="goBack"></span>
              <span @click="goBack">返回</span>
            </div>
            <img src="@/assets/logo.png" @click="goHome" />
          </div>
          <div class="layout-nav">
            <Poptip v-if="xh_userInfo.user.dwmc" trigger="hover">
              <div slot="content" class="userPop">
                <div @click="updatePwd">修改密码</div>
                <div class="outBtn" @click="goLogout">退出登录</div>
              </div>
              <div class="user">
                <span class="iconfont icon-chazhaoyonghu"></span>
                <div>{{ xh_userInfo.user.dwmc }}</div>
              </div>
            </Poptip>
            <div v-else class="user" @click="$refs['vipbox'].showLogin = true">
              <span class="iconfont icon-chazhaoyonghu"></span>
              <div>用户登录</div>
            </div>
          </div>
        </Menu>
      </Header>
      <Layout>
        <Sider :style="{ background: '#fff' }" hide-trigger>
          <Menu
            ref="side_menu"
            :active-name="activeName"
            :open-names="openNames"
            theme="light"
            width="auto"
            @on-select="chooseMenu"
          >
            <div v-for="(it, i) in menuList" :key="i">
              <Submenu v-if="it.option" :name="it.name">
                <template slot="title">
                  <span :class="[it.icon]" class="iconfont"></span>
                  {{ it.name }}
                </template>
                <MenuItem
                  v-for="(item, index) in it.option"
                  :key="index"
                  :name="item.name"
                  >{{ item.name }}
                </MenuItem>
              </Submenu>
              <MenuItem v-else :name="it.name">
                <span :class="[it.icon]" class="iconfont"></span>
                {{ it.name }}
              </MenuItem>
            </div>
          </Menu>
        </Sider>
        <div
          :style="{ height: contentHeight }"
          style="overflow-y: scroll; width: 85%"
        >
          <Layout v-if="menuObj.name == '首页'" :style="{ padding: '10px' }">
            <card>
              <syBox @changeMenu="changeMenu"></syBox>
              <div v-if="expireFlagLoading" class="gradient-mask">
                <h2>
                  温馨提示：未缴纳会费，请与协会联系，联系电话：0851-86587786
                  联系人：丁筑晓
                </h2>
              </div>
            </card>
          </Layout>
          <Layout v-if="menuObj.name == '通知'" :style="{ padding: '10px' }">
            <Card>
              <tzBox :params="{ pageNo: 1, pageSize: 5 }"></tzBox>
              <div v-if="expireFlagLoading" class="gradient-mask">
                <h2>
                  温馨提示：未缴纳会费，请与协会联系，联系电话：0851-86587786
                  联系人：丁筑晓
                </h2>
              </div>
            </Card>
          </Layout>
          <Layout
            v-if="menuObj.name == '会费管理'"
            :style="{ padding: '10px' }"
          >
            <Card>
              <hfglBox></hfglBox>
              <div v-if="expireFlagLoading" class="gradient-mask">
                <h2>
                  温馨提示：未缴纳会费，请与协会联系，联系电话：0851-86587786
                  联系人：丁筑晓
                </h2>
              </div>
            </Card>
          </Layout>
          <Layout
            v-if="menuObj.name == '会员证书'"
            :style="{ padding: '10px' }"
          >
            <Card>
              <hyzsBox></hyzsBox>
              <div v-if="expireFlagLoading" class="gradient-mask">
                <h2>
                  温馨提示：未缴纳会费，请与协会联系，联系电话：0851-86587786
                  联系人：丁筑晓
                </h2>
              </div>
            </Card>
          </Layout>
          <Layout
            v-if="menuObj.name == '会议报名'"
            :style="{ padding: '10px' }"
          >
            <Card>
              <hybmBox></hybmBox>
              <div v-if="expireFlagLoading" class="gradient-mask">
                <h2>
                  温馨提示：未缴纳会费，请与协会联系，联系电话：0851-86587786
                  联系人：丁筑晓
                </h2>
              </div>
            </Card>
          </Layout>
          <Layout
            v-if="menuObj.name == '基本信息'"
            :style="{ padding: '10px' }"
          >
            <!-- xh_userInfo.user.ext5 //会员类型:1省内企业,2.省外企业,3.个人 -->
            <Card>
              <jbxxSnBox v-if="xh_userInfo.user.ext5 == 1"></jbxxSnBox>
              <jbxxSwBox v-if="xh_userInfo.user.ext5 == 2"></jbxxSwBox>
              <jbxxRrBox v-if="xh_userInfo.user.ext5 == 3"></jbxxRrBox>
              <div v-if="expireFlagLoading" class="gradient-mask">
                <h2>
                  温馨提示：未缴纳会费，请与协会联系，联系电话：0851-86587786
                  联系人：丁筑晓
                </h2>
              </div>
            </Card>
          </Layout>
          <Layout
            v-if="menuObj.name == '从业人员'"
            :style="{ padding: '10px' }"
          >
            <Card>
              <ryxxBox></ryxxBox>
              <div v-if="expireFlagLoading" class="gradient-mask">
                <h2>
                  温馨提示：未缴纳会费，请与协会联系，联系电话：0851-86587786
                  联系人：丁筑晓
                </h2>
              </div>
            </Card>
          </Layout>
          <Layout
            v-if="menuObj.name == '项目负责人'"
            :style="{ padding: '10px' }"
          >
            <Card>
              <xmfzrBox></xmfzrBox>
              <div v-if="expireFlagLoading" class="gradient-mask">
                <h2>
                  温馨提示：未缴纳会费，请与协会联系，联系电话：0851-86587786
                  联系人：丁筑晓
                </h2>
              </div>
            </Card>
          </Layout>
          <Layout
            v-if="menuObj.name == '信用评价'"
            :style="{ padding: '10px' }"
          >
            <Card>
              <div class="common_excel_no_more">暂无内容~</div>
            </Card>
          </Layout>
          <Layout
            v-if="menuObj.name == '优秀会员'"
            :style="{ padding: '10px' }"
          >
            <Card>
              <div class="common_excel_no_more">暂无内容~</div>
            </Card>
          </Layout>
          <Layout
            v-if="menuObj.name == '公益讲座'"
            :style="{ padding: '10px' }"
          >
            <Card>
              <publicLecture />
              <div v-if="expireFlagLoading" class="gradient-mask">
                <h2>
                  温馨提示：未缴纳会费，请与协会联系，联系电话：0851-86587786
                  联系人：丁筑晓
                </h2>
              </div>
            </Card>
          </Layout>
          <Layout
            v-if="menuObj.name == '课程中心'"
            :style="{ padding: '10px' }"
          >
            <Card>
              <course />
              <div v-if="expireFlagLoading" class="gradient-mask">
                <h2>
                  温馨提示：未缴纳会费，请与协会联系，联系电话：0851-86587786
                  联系人：丁筑晓
                </h2>
              </div>
            </Card>
          </Layout>
          <Layout
            v-if="menuObj.name == '已报课程'"
            :style="{ padding: '10px' }"
          >
            <Card>
              <enrolledCourse />
              <div v-if="expireFlagLoading" class="gradient-mask">
                <h2>
                  温馨提示：未缴纳会费，请与协会联系，联系电话：0851-86587786
                  联系人：丁筑晓
                </h2>
              </div>
            </Card>
          </Layout>
          <Layout v-if="menuObj.url == 'zcfg'" :style="{ padding: '10px' }">
            <Card>
              <zcfgBox
                ref="zcfgBox"
                :params="{ pageNo: 1, pageSize: 5 }"
              ></zcfgBox>
              <div v-if="expireFlagLoading" class="gradient-mask">
                <h2>
                  温馨提示：未缴纳会费，请与协会联系，联系电话：0851-86587786
                  联系人：丁筑晓
                </h2>
              </div>
            </Card>
          </Layout>
          <Layout
            v-if="menuObj.name == '企业风采'"
            :style="{ padding: '10px' }"
          >
            <Card>
              <qyfcBox :params="{ pageNo: 1, pageSize: 5 }"></qyfcBox>
              <div v-if="expireFlagLoading" class="gradient-mask">
                <h2>
                  温馨提示：未缴纳会费，请与协会联系，联系电话：0851-86587786
                  联系人：丁筑晓
                </h2>
              </div>
            </Card>
          </Layout>
        </div>
      </Layout>
    </Layout>
    <upPwd ref="pwdbox"></upPwd>
    <vipLogin ref="vipbox"></vipLogin>
    <Back-top :bottom="120" :height="100">
      <div class="iconfont icon-shangjiantou"></div>
    </Back-top>
  </div>
</template>
<script>
import upPwd from "@/components/upPwd.vue";
import vipLogin from "@/components/vip_login.vue";
import syBox from "./components/sy/index.vue";
import tzBox from "./components/tz/tz.vue";
import hfglBox from "./components/hfgl/index.vue";
import hyzsBox from "./components/hyzs/index.vue";
import hybmBox from "./components/hybm/index.vue";
import jbxxRrBox from "./components/jbxx/gr.vue";
import jbxxSnBox from "./components/jbxx/sn.vue";
import jbxxSwBox from "./components/jbxx/sw.vue";
import ryxxBox from "./components/ryxx/index.vue";
import qyfcBox from "./components/qyfc/qyfc.vue";
import zcfgBox from "./components/zcfg/index.vue";
import xmfzrBox from "./components/xmfzr/index.vue";
import publicLecture from "./components/educationTrain/publicLecture/index.vue";
import course from "./components/educationTrain/course/index.vue";
import enrolledCourse from "./components/educationTrain/enrolledCourse/index.vue";
import { loginout } from "@/api/login";
import router from "@/router";

export default {
  components: {
    upPwd,
    vipLogin,
    syBox,
    tzBox,
    hfglBox,
    hyzsBox,
    hybmBox,
    jbxxRrBox,
    jbxxSnBox,
    jbxxSwBox,
    ryxxBox,
    qyfcBox,
    zcfgBox,
    xmfzrBox,
    publicLecture,
    course,
    enrolledCourse,
  },
  data() {
    return {
      activeName: "首页",
      contentHeight: "",
      menuList: [
        {
          name: "首页",
          icon: "icon-shouye",
          url: "/",
        },
        {
          name: "通知",
          icon: "icon-tongzhi",
          url: "news",
          params: {
            name: encodeURIComponent("通知公告"),
          },
        },
        {
          name: "会议",
          icon: "icon-huiyi",
          option: [
            {
              name: "会议报名",
              url: "meeting",
            },
          ],
        },
        {
          name: "教育培训",
          icon: "icon-datixitongguanli",
          option: [
            {
              name: "公益讲座",
            },
            {
              name: "课程中心",
            },
            {
              name: "已报课程",
            },
          ],
        },

        {
          name: "会员信息",
          icon: "icon-huiyuan",
          option: [
            {
              name: "基本信息",
              url: "",
            },
            {
              name: "会费管理",
              url: "tax",
            },
            {
              name: "会员证书",
              url: "member",
              params: {
                name: encodeURIComponent("会员证书"),
              },
            },
          ],
        },

        {
          name: "人员情况",
          icon: "icon-danwei",
          option: [
            {
              name: "从业人员",
              url: "people",
            },
            {
              name: "项目负责人",
              url: "",
            },
          ],
        },
        {
          name: "评优",
          icon: "icon-huiyuan",
          option: [
            {
              name: "信用评价",
              url: "",
            },
            {
              name: "优秀会员",
              url: "people",
            },
          ],
        },

        {
          name: "政策法规",
          icon: "icon-danwei",
          option: [
            {
              name: "国家法律",
              url: "zcfg",
            },
            {
              name: "国家行政法规",
              url: "zcfg",
            },
            {
              name: "国务院规范性文件",
              url: "zcfg",
            },
            {
              name: "住建部规章及规范性文件",
              url: "zcfg",
            },
            {
              name: "其他部门规章及规范性文件",
              url: "zcfg",
            },
          ],
        },
        {
          name: "企业风采",
          icon: "icon-zhaopin",
          url: "qyfc",
          params: {
            name: encodeURIComponent("企业风采"),
          },
        },
        {
          name: "网站首页",
          icon: "icon-shouye",
          url: "/",
        },
      ],
      xh_userInfo: {
        user: {
          username: "",
        },
      },
      newList: [],
      ryList: [],
      hyList: [],
      menuObj: {
        name: "首页",
      },
      openNames: [],
      expireFlagLoading: false,
    };
  },
  watch: {
    openNames() {
      this.$nextTick(() => {
        this.$refs.side_menu.updateOpened();
        this.$refs.side_menu.updateActiveName();
      });
    },
  },
  mounted() {
    if (this.$Cookies.get("xh_userInfo")) {
      this.xh_userInfo = JSON.parse(this.$Cookies.get("xh_userInfo"));
    }

    // const height = window.innerHeight;
    this.contentHeight = `calc(${100}vh - 64px - 0px)`;
    if (this.xh_userInfo.expireFlag === 1) {
      this.$Modal.confirm({
        title: "提示",
        content:
          "未缴纳会费，请与协会联系，联系电话：0851-86587786  联系人：丁筑晓",
        cancelText: "关闭",
      });
      this.expireFlagLoading = true;
    } else {
      this.expireFlagLoading = false;
    }
  },
  methods: {
    /* ====================数据请求部分========================== */

    /* ====================页面交互部分========================== */
    goHome() {
      this.$router.push("/");
    },
    goBack() {
      this.$router.back();
    },
    updatePwd() {
      // console.log(this.$refs['pwdbox'])
      this.$refs["pwdbox"].showLogin = true;
    },
    changeMenu(name) {
      this.activeName = name;
      this.menuList.map((v) => {
        if (v.name == name) {
          this.openNames = [v.name];
        } else {
          if (v.option) {
            v.option.map((k) => {
              if (k.name == name) {
                this.openNames = [v.name];
              }
            });
          }
        }
      });
      this.menuObj = {
        name: name,
      };
    },
    chooseMenu(e) {
      console.log("name", e);
      if (this.xh_userInfo.expireFlag === 1 && e !== "网站首页") {
        this.$Modal.confirm({
          title: "提示",
          content:
            "未缴纳会费，请与协会联系，联系电话：0851-86587786  联系人：丁筑晓",
          cancelText: "关闭",
        });
        this.expireFlagLoading = true;
      } else {
        this.expireFlagLoading = false;
      }
      let obj = {};
      this.menuList.map((v) => {
        if (v.name == e) {
          obj = v;
        } else {
          if (v.option) {
            v.option.map((k) => {
              if (k.name == e) {
                obj = k;
              }
            });
          }
        }
      });
      console.log("=======obj========", obj);
      this.menuObj = obj;
      if (this.menuObj.url && this.menuObj.url == "zcfg") {
        this.$nextTick(() => {
          console.log("===========zcfgBox=============", this.$refs["zcfgBox"]);
          this.$refs["zcfgBox"].typeName = this.menuObj.name;
        });
      }

      if (obj.name == "网站首页") {
        this.$router.push("/");
      }
    },
    goLogout() {
      let token = this.$Cookies.get("xh_userInfo")
        ? JSON.parse(this.$Cookies.get("xh_userInfo")).token
        : "";
      loginout({
        token,
      }).then((res) => {
        if (res.code == 200 || res.code == 0 || res.code == 401) {
          this.$Modal.confirm({
            title: "提示",
            content: "确定退出登录吗？",
            onOk: () => {
              this.$Message.success("退出成功！");
              this.$Cookies.remove("xh_userInfo");
              this.goHome();
              location.reload();
            },
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.gradient-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(55, 55, 55, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  h2 {
    color: #ff0000;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 5px;
    border-radius: 5px;
  }
}
/deep/ .ivu-menu-horizontal {
  height: 64px;
  line-height: 64px;
}

/deep/ .ivu-layout-header {
  background-color: #4381e6;

  .ivu-menu-dark {
    background-color: #4381e6;
  }
}

/deep/ .ivu-card {
  margin: 8px;
}

.layout {
  border: 1px solid #d7dde4;
  border-radius: 4px;
  background: #f5f7f9;
  min-width: 1400px;
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.layout-logo {
  border-radius: 3px;
  float: left;
  position: relative;
  top: 0;
  left: 0px;
  display: flex;
  align-items: center;
  height: 100%;

  img {
    height: 50px;
    cursor: pointer;
    vertical-align: top;
  }

  .nav_box {
    color: #fff;
    margin-right: 30px;
    display: flex;
    align-items: center;

    span {
      cursor: pointer;
    }

    .icon-fanhui {
      font-size: 20px;
      margin-right: 8px;
    }
  }
}

/deep/ .ivu-tooltip-inner {
  background-color: #fff;
  color: #011243;
}

/deep/ .ivu-tooltip-arrow {
  border-bottom-color: #fff;
}

.layout-nav {
  float: right;
  margin: 0 auto;
  margin-right: 50px;
  color: #fff;
  font-size: 15px;
}

.userPop {
  font-size: 15px;
  text-align: center;

  > div {
    padding: 8px 0;
    cursor: pointer;
    color: #666;
  }

  > div:hover {
    // color: #2D85E2;
  }

  .outBtn {
    border-top: 1px solid #eee;
  }
}

.user {
  display: flex;
  align-items: center;
  margin-left: 30px;
  font-size: 15px;
  cursor: pointer;
  white-space: nowrap;

  .icon-chazhaoyonghu {
    margin-right: 10px;
  }
}

.layout-footer-center {
  text-align: center;
}

.user_box {
  display: flex;
  align-items: center;
  font-size: 16px;

  > div {
    display: flex;
    align-items: center;
    width: 33.33%;
    height: 100%;

    .avater {
      height: 80px;

      img {
        height: 100%;
        vertical-align: top;
      }
    }

    .user_info {
      margin-left: 20px;
    }

    .xgmm {
      font-size: 16px;
      background-color: #4381e6;
      color: #fff;
      padding: 8px 20px;
      border-radius: 20px;
      cursor: pointer;
    }
  }
}

/deep/ .ivu-col {
  height: 100%;
}

/deep/ .ivu-menu {
  font-size: 16px;
}

/deep/ .ivu-menu-item {
  font-size: 16px;
}

.box1_height {
  // margin-top: -15px;

  .box1_content {
    font-size: 14px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 15px 5px;
    // margin-top: 20px;
    cursor: pointer;

    .text {
      margin: 0 20px;
      font-size: 15px;
    }

    .tag_line {
      width: 4px;
      height: 19px;
      background: linear-gradient(270deg, #2d85e2 2%, #5b43df 100%);
    }
  }

  .box1_content:hover {
    background-color: #eee;
  }
}

.ry_box {
  margin-top: 10px;

  .cell {
    display: flex;
    width: 100%;
    white-space: nowrap;
    font-size: 14px;
    padding: 10px 0;
    text-align: center;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;

    > div:first-child {
      width: 10%;
    }

    > div:nth-child(2) {
      width: 15%;
    }

    > div:nth-child(3) {
      width: 25%;
    }

    > div:nth-child(4) {
      width: 40%;
    }
  }

  .cellHY {
    > div:first-child {
      width: 10%;
    }

    > div:nth-child(2) {
      width: 30%;
    }

    > div:nth-child(3) {
      width: 60%;
    }
  }

  .th {
    background-color: #49b3ff;
    color: #fff;
  }

  .td {
    cursor: pointer;
  }

  .td:hover {
    background-color: #eee;
  }

  .td:nth-child(odd) {
    background-color: #d8d8d8;
  }

  .td:nth-child(even) {
    border-bottom: 1px solid #eee;
  }
}
</style>
