<template>
  <div class="tz_box">
    <Tabs value="name1">
      <Tab-pane label="已报课程" name="name1">
        <div class="cell_box">
          <el-table
            v-loading="loading"
            :cell-style="{ padding: 0 }"
            :data="ryList"
            :header-cell-style="{
              lineHeight: '35px',
              height: '35px',
              padding: 0,
              backgroundColor: '#49b3ff',
              color: '#515a6e',
            }"
            style="width: 100%"
          >
            <el-table-column
              align="center"
              label="序号"
              type="index"
              width="80"
            ></el-table-column>

            <el-table-column
              align="center"
              label="报名时间"
              prop="enrollTime"
              show-overflow-tooltip
              width="200px"
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="培训课程"
              prop="courseName"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              align="center"
              label="报名状态"
              prop="confirm"
              show-overflow-tooltip
              width="90px"
            >
              <template #default="scope">
                {{ scope.row.confirm == 1 ? "缴费成功" : "待缴费" }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="学习进度"
              prop="studyFinish"
              show-overflow-tooltip
              width="90px"
            >
              <template #default="scope">
                {{ scope.row.studyFinish == 1 ? "已完成" : "未完成" }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="总学时"
              prop="classHourTotal"
              show-overflow-tooltip
              width="90px"
            >
              <template #default="scope">
                {{ scope.row.classHourTotal }}学时
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="考试分数"
              prop="studyScore"
              show-overflow-tooltip
              width="90px"
            >
            </el-table-column>

            <el-table-column
              align="center"
              fixed="right"
              label="操作"
              width="180px"
            >
              <template #default="scope">
                <el-button
                  :disabled="!(scope.row.confirm == 1)"
                  type="text"
                  @click="goDetail(scope.row)"
                >
                  {{ scope.row.studyFinish == 1 ? "重新学习" : "立即学习" }}
                </el-button>
                <!-- 判断是否可以考试 confirm：待缴费：0，缴费成功：1；studyFinish：已完成：1，未完成：0，examine：考核方式 0不考试 1考试           -->
                <el-button
                  v-if="
                    scope.row.confirm === '1' &&
                    scope.row.studyFinish === '1' &&
                    scope.row.examine === '1'
                  "
                  :disabled="Number(scope.row.studyScore) >= 60"
                  type="text"
                  @click="goKS(scope.row)"
                >
                  在线考试
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <Page
          v-if="ryList.length != 0"
          :current="params.pageNo"
          :page-size="params.pageSize"
          :total="total"
          class="common_page_box"
          show-total
          @on-change="changePage"
        ></Page>
      </Tab-pane>
    </Tabs>
    <ksLogin ref="ksbox"></ksLogin>
    <detailModal ref="detailModal"></detailModal>
  </div>
</template>

<script>
import ksLogin from "@/components/ks_login.vue";
import {
  checkStudyBefore,
  courseEnrollCheck,
  enrollInfo,
} from "@/api/home/guild";
import detailModal from "@/views/cyry/components/xx/detail.vue";
import { toKsxt } from "@/api/home/basicInfo";

export default {
  name: "home",
  components: {
    detailModal,
    ksLogin,
  },
  data() {
    return {
      total: 0,
      params: {
        pageNo: 1,
        pageSize: 10,
      },
      form: {},
      ryList: [],
      userInfo: {},
      typeName: "",
      catid: "",
      loading: false,
    };
  },
  created() {
    this.getArticle();
  },
  methods: {
    showInfo(obj) {
      console.log("===========obj=============", obj);
      this.showText = this.formatStatus(obj);
      this.showPop = true;
    },
    formatStatus(obj) {
      //console.log("=======formatStatus========", obj, typeof(obj.status))
      if (obj.status == 1) return "审核通过";
      if (obj.status == 2)
        return "审核不通过" + (obj.otherinfo ? "|" + obj.otherinfo : "");
      return "待审核";
    },
    changePage(e) {
      this.params.pageNo = e;
      this.getArticle();
    },
    getArticle() {
      this.loading = true;
      enrollInfo({
        ...this.params,
        token: this.$Cookies.get("cyry_token"),
      }).then((res) => {
        if (res.code == 200 || res.code == 0) {
          let info = res.result.records;
          this.ryList = info;
          this.total = res.result.total;
        } else if (res.code == 500) {
          this.$Modal.error({
            title: "错误",
            content: res.message,
            onOk: () => {
              this.$Cookies.remove("cyry_user");
              this.$Cookies.remove("cyry_token");
              this.$refs["ksbox"].showLogin = true;
            },
          });
        }
        this.loading = false;
      });
    },
    toAdd() {
      this.$refs["detailModal"].showPop = true;
      this.$refs["detailModal"].id = "";
    },
    toReset() {
      this.form = {};
      this.params.pageNo = 1;
      this.getArticle();
    },
    goDetail(it) {
      // this.$refs["detailModal"].showPop = true;
      // this.$refs["detailModal"].id = it.id;
      let cyry_token = this.$Cookies.get("cyry_token");
      if (!cyry_token) {
        this.$Modal.confirm({
          title: "提示",
          content: "未登录，请登录从业人员信息后开始学习。",
          okText: "去登录",
          onOk: () => {
            this.$refs["ksbox"].showLogin = true;
          },
        });
        return;
      }
      checkStudyBefore({
        courseId: it.courseId,
        token: this.$Cookies.get("cyry_token"),
      })
        .then((res) => {
          if (res.code == 200) {
            let url = "subjectDetail";
            this.$router.push({
              name: url,
              query: {
                id: it.courseId,
                name: encodeURIComponent("已报课程"),
              },
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    async goKS(row) {
      try {
        if (row.studyFinish === "1") {
          // 判断是否学习完成
          let cyry_token = this.$Cookies.get("cyry_token"); // 获取从业人员token
          if (!cyry_token) {
            // 判断是否有token
            this.$Modal.confirm({
              title: "提示",
              content: "未登录，请登录从业人员信息后跳转到考试系统。",
              okText: "去登录",
              onOk: () => {
                this.$refs["ksbox"].showLogin = true;
              },
            });
            return;
          }
          // 校验是否参加过培训课程的考试
          const res1 = await courseEnrollCheck({
            courseId: row.courseId,
            token: cyry_token,
          });
          console.log("res1", res1);
          if (res1.code !== 200) return;
          // 获取考试系统的token
          const res2 = await toKsxt({ token: cyry_token });
          if (res2.code !== 200) return;
          let token = res2.result.token || "";
          this.$Modal.confirm({
            title: "温馨提示",
            okText: "前往考试",
            cancelText: "暂不考试",
            content: `温馨提示：您已完成“${row.courseName}”课程的学习，可参加资格考试。`,
            onOk: () => {
              let url = `${this.$ksHost}/#/subject?type=1&courseId=${
                row.courseId
              }&studentId=${
                JSON.parse(this.$Cookies.get("cyry_user")).studentId
              }&enrollId=${row.id}&token=${token}`;
              window.open(url, "_blank");
              console.log("res2", res2);
            },
          });
        } else {
          this.$Modal.confirm({
            title: "提示",
            content: "还未学习完成，请学习完成后参加考试！",
            onOk: () => {},
          });
        }
      } catch (error) {
        console.error("error:", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ivu-tabs-nav .ivu-tabs-tab {
  width: 200px;
  text-align: center;
  font-size: 18px;
}

.tz_box {
  margin: 10px 20px;
  // width: 100%;
  // max-width: 1000px;

  .search_box {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 20px 0;

    > div {
      margin: 10px;
      white-space: nowrap;
      cursor: pointer;
    }

    .btn1 {
      background: linear-gradient(270deg, #2d85e2 2%, #5b43df 100%);
      color: #fff;
      padding: 5px 15px;
    }

    .btn2 {
      background: #767676;
      color: #fff;
      padding: 5px 15px;
    }
  }

  .cell_box {
    margin-top: 20px;
  }
}
</style>
