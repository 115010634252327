var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"basicInfo-view"},[_c('div',{staticClass:"basicInfo-head"},[_c('el-card',[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("基本信息")]),_c('el-button',{staticStyle:{"float":"right"},attrs:{"size":"small","type":"primary"},on:{"click":_vm.goUpdate}},[_vm._v("修改资料 ")])],1),_c('div',[_c('el-descriptions',[_c('el-descriptions-item',{attrs:{"label":"姓名"}},[_vm._v(_vm._s(_vm.cyry_user.name)+" ")]),_c('el-descriptions-item',{attrs:{"label":"手机号"}},[_vm._v(_vm._s(_vm.cyry_user.phone)+" ")]),_c('el-descriptions-item',{attrs:{"label":"身份证号"}},[_vm._v(_vm._s(_vm.cyry_user.sfz)+" ")]),_c('el-descriptions-item',{attrs:{"label":"公司"}},[_vm._v(_vm._s(_vm.cyry_user.dw)+" ")])],1)],1)])],1),_c('div',{staticClass:"basicInfo-center"},[_c('div',{staticClass:"basicInfo-item"},[_c('el-card',{staticStyle:{"height":"100%"},attrs:{"body-style":{ padding: '10px' }}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("报名信息")])]),_c('div',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"cell-style":{ padding: 0 },"data":_vm.enrollsData,"header-cell-style":{
              lineHeight: '35px',
              height: '35px',
              padding: 0,
            },"height":"300"}},[_c('el-table-column',{attrs:{"align":"center","label":"姓名","prop":"name","show-overflow-tooltip":"","width":"80px"}}),_c('el-table-column',{attrs:{"align":"center","label":"电话","prop":"phone","show-overflow-tooltip":"","width":"120px"}}),_c('el-table-column',{attrs:{"align":"center","label":"身份证号","prop":"idCard","show-overflow-tooltip":"","width":"120px"}}),_c('el-table-column',{attrs:{"align":"center","label":"公司","prop":"company","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"align":"center","label":"作废状态","prop":"zfrq","show-overflow-tooltip":"","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.zfrq ? "作废" : "正常")+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","fixed":"right","label":"操作","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.enrollsClick(scope.row)}}},[_vm._v("详情 ")])]}}])})],1)],1)])],1),_c('div',{staticClass:"basicInfo-item"},[_c('el-card',{staticStyle:{"height":"100%"},attrs:{"body-style":{ padding: '10px' }}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("考试记录")])]),_c('div',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"calc(100%- 20px)"},attrs:{"cell-style":{ padding: '8px' },"data":_vm.scoresData,"header-cell-style":{
              lineHeight: '35px',
              height: '35px',
              padding: 0,
            },"height":"300"}},[_c('el-table-column',{attrs:{"align":"center","label":"姓名","prop":"name","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.cyry_user.name)+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"手机号","prop":"phone","show-overflow-tooltip":"","width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.cyry_user.phone)+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"考试结果","prop":"score","show-overflow-tooltip":"","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.type == 3 ? "免考" : scope.row.score)+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"考试时间","prop":"kssj","show-overflow-tooltip":"","width":"150px"}})],1)],1)])],1),_c('div',{staticClass:"basicInfo-item"},[_c('el-card',{staticStyle:{"height":"100%"},attrs:{"body-style":{ padding: '10px' }}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("证书记录")])]),_c('div',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"cell-style":{ padding: 0 },"data":_vm.certificatesData,"header-cell-style":{
              lineHeight: '35px',
              height: '35px',
              padding: 0,
            },"height":"300"}},[_c('el-table-column',{attrs:{"align":"center","label":"姓名","prop":"certName","show-overflow-tooltip":"","width":"80px"}}),_c('el-table-column',{attrs:{"align":"center","label":"性别","prop":"certSex","show-overflow-tooltip":"","width":"60px"}}),_c('el-table-column',{attrs:{"align":"center","label":"公司","prop":"certCompany","show-overflow-tooltip":"","width":"150px"}}),_c('el-table-column',{attrs:{"align":"center","label":"生效日期","prop":"yxqq","show-overflow-tooltip":"","width":"120px"}}),_c('el-table-column',{attrs:{"align":"center","label":"失效日期","prop":"yxqz","show-overflow-tooltip":"","width":"110px"}}),_c('el-table-column',{attrs:{"align":"center","fixed":"right","label":"操作","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.certificatesClick(scope.row)}}},[_vm._v("详情 ")])]}}])})],1)],1)])],1),_c('div',{staticClass:"basicInfo-item"},[_c('el-card',{staticStyle:{"height":"100%"},attrs:{"body-style":{ padding: '10px' }}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("培训记录")])]),_c('div',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"cell-style":{ padding: 0 },"data":_vm.courseStudyTotalsData,"header-cell-style":{
              lineHeight: '35px',
              height: '35px',
              padding: 0,
            },"height":"300"}},[_c('el-table-column',{attrs:{"align":"center","label":"培训课程","prop":"courseName","show-overflow-tooltip":"","width":"300px"}}),_c('el-table-column',{attrs:{"align":"center","label":"学习时间","prop":"updateTime","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"align":"center","label":"学习状态","prop":"studyFinish","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.studyFinish == "1" ? "已完成" : scope.row.studyFinish == "0" ? "学习中" : "")+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.courseStudyTotalsClick(scope.row)}}},[_vm._v("详情 ")])]}}])})],1)],1)])],1)]),_c('EnrollsDetails',{attrs:{"details":_vm.enrollsDetails},on:{"handleClose":_vm.detailsClose}}),_c('CertificatesDetails',{attrs:{"details":_vm.certificatesDetails},on:{"handleClose":_vm.detailsClose}}),_c('CourseStudyTotalsDetails',{attrs:{"details":_vm.courseStudyTotalsDetails},on:{"handleClose":_vm.detailsClose}}),_c('ksLogin',{ref:"ksbox"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }