var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tz_box"},[_c('Tabs',{attrs:{"value":"name1"}},[_c('Tab-pane',{attrs:{"label":"课程中心","name":"name1"}},[_c('div',{staticClass:"cell_box"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"cell-style":{ padding: 0 },"data":_vm.ryList,"header-cell-style":{
              lineHeight: '35px',
              height: '35px',
              padding: 0,
              backgroundColor: '#49b3ff',
              color: '#515a6e',
            },"stripe":""}},[_c('el-table-column',{attrs:{"align":"center","label":"序号","type":"index","width":"80"}}),_c('el-table-column',{attrs:{"align":"center","label":"课程名称","prop":"name","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"align":"center","label":"课程学时","prop":"classHour","show-overflow-tooltip":"","width":"150"}}),_c('el-table-column',{attrs:{"align":"center","label":"课程价格","prop":"classPrice","show-overflow-tooltip":"","width":"250"}}),_c('el-table-column',{attrs:{"align":"center","label":"学习方式","prop":"name","show-overflow-tooltip":"","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.study == 0 ? "线下" : scope.row.study == 1 ? "线上" : "")+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","fixed":"right","label":"操作","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.goDetail(scope.row)}}},[_vm._v("立即报名")])]}}])})],1)],1),(_vm.ryList.length != 0)?_c('Page',{staticClass:"common_page_box",attrs:{"current":_vm.params.pageNo,"page-size":_vm.params.pageSize,"total":_vm.total,"show-total":""},on:{"on-change":_vm.changePage}}):_vm._e()],1)],1),_c('vipLogin',{ref:"vipbox"}),(_vm.details.dialogVisible)?_c('Detail',{attrs:{"details":_vm.details},on:{"handleClose":_vm.handleClose,"submitBtn":_vm.submitBtn}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }