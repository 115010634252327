<template>
  <div class="container" style="background-color: #fff">
    <headerBox></headerBox>
    <bannerBox ref="bannerChild"></bannerBox>
    <div class="content_box">
      <div class="content_box2" style="overflow-x: scroll">
        <div style="min-width: 950px">
          <div class="common_info">
            <div class="info_left">
              <Form ref="uform" :label-width="120" :model="vipInfo">
                <FormItem label="单位名称">
                  <Input v-model="vipInfo.dwmc" readonly></Input>
                </FormItem>
                <FormItem label="统一社会信用代码">
                  <Input v-model="vipInfo.uscc" readonly></Input>
                </FormItem>
                <FormItem label="企业法人">
                  <Input v-model="vipInfo.frxm" readonly></Input>
                </FormItem>
                <FormItem label="会员类型">
                  <Input v-model="vipInfo.type" readonly></Input>
                </FormItem>
                <FormItem label="单位地址">
                  <Input v-model="vipInfo.txdz" readonly></Input>
                </FormItem>
              </Form>
            </div>
            <div class="info_right">
              <img
                v-if="vipInfo.resource"
                :src="$previewImg + vipInfo.resource"
                width="260px"
              />
            </div>
          </div>
          <div v-if="!vipInfo.uuid" class="common_excel_no_more">暂无内容~</div>
        </div>
      </div>
    </div>
    <footerBox></footerBox>
    <Back-top :bottom="120" :height="100">
      <div class="iconfont icon-shangjiantou"></div>
    </Back-top>
  </div>
</template>

<script>
import bannerBox from "@/components/banner.vue";
import headerBox from "@/components/header.vue";
import footerBox from "@/components/footer.vue";
import { getDetatilArticle } from "@/api/home/index";
import { getVipInfo } from "@/api/home/index";
export default {
  name: "vipInfo",
  components: {
    bannerBox,
    headerBox,
    footerBox,
  },
  data() {
    return {
      vipInfo: {},
      params: {
        id: 0,
      },
      tabList1: [
        {
          label: "会长单位",
          value: 1,
        },
        {
          label: "副会长单位",
          value: 2,
        },
        {
          label: "监事单位",
          value: 3,
        },
        {
          label: "常务理事单位",
          value: 4,
        },
        {
          label: "副秘书长",
          value: 5,
        },
        {
          label: "理事单位",
          value: 6,
        },
        {
          label: "会员单位",
          value: 7,
        },
        {
          label: "监事长",
          value: 29,
        },
      ],
    };
  },
  mounted() {
    this.params.id = Number(this.$route.query.id);
    this.getVipInfo();
  },
  methods: {
    /* ====================数据请求部分========================== */
    getVipInfo() {
      getVipInfo({
        ...this.params,
      }).then((res) => {
        if (res.code == 200 || res.code == 0) {
          this.vipInfo = res.result;
          let item = this.tabList1.find((d) => d.value == this.vipInfo.type);
          this.vipInfo.type = item.label;
        } else {
          this.vipInfo = {};
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content_box {
  margin: 80px 200px;

  .content_box2 {
    background-color: #c6ddf4;
    border-color: #e3e5e8;
    border-radius: 15px;
    padding: 80px;
  }

  .common_info {
    margin: 20px;
    .info_left {
      width: 68%;
      float: left;
    }

    .info_right {
      width: 26%;
      float: right;
    }
  }
}
</style>
